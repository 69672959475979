body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a.visited {
    color: #324DC7;
}

/* :root {
  --content-builder-content-width: 880px;
  --content-builder-content-width-fullscreen: calc(100vw - 100px);
  
  --content-builder-ui-tint-color: #324DC7;
  --content-builder-ui-font: inherit;
  
  --content-builder-canvas-tint-color: #324DC7;
  --content-builder-canvas-font: -apple-system;

  --content-builder-highlight-color: #0085FF;

  --content-builder-color-red: red; 
  --content-builder-color-orange: #FF8A00;
  --content-builder-color-icon-grey: #838383;
  --content-builder-color-background: #F6F6F6;
} */

@media (min-width: 1300px) {
  :root {
    /* Content sizing */
    /* --content-builder-content-width: 1200px; */
    --content-builder-content-width-fullscreen: 1200px;
  }  
}

/**
 * Example of using custom container class 
 * 
 * These values taken from college board dev 
 */
.container {
  max-width: 100%;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%; 
} 

@media (min-width: 1px) {
  .container {
      max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
      max-width: 720px;
  }
}

@media (min-width: 1024px) {
  .container {
      max-width: 976px;
  }
}

@media (min-width:1248px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width:1344px) {
  .container {
    max-width: 1296px;
  }
}

